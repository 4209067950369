<template>
  <v-main>

    <v-container
        id="users-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-bank"
          class="px-5 py-5"
      >

        <v-responsive>

        <v-card-text v-if="invoice">

          <div>
            <v-alert
                type="error"
                outlined
                v-for="(error, i) in errors"
                :key="i"
                dense
            >
              {{ error }}
            </v-alert>
            <v-alert
                type="success"
                outlined
                v-for="(message, i) in messages"
                :key="i"
                dense
            >
              {{ message }}
            </v-alert>
          </div>

          <v-row
            justify="space-between"
          >
            <v-col
                cols="12"
                lg="4"
                sm="12"
            >
              <v-card outlined>

                <v-list-item two-line>
                  <v-list-item-content>

                    <v-list-item-title class="text-h5 mb-4">
                      Bill To:
                    </v-list-item-title>
                    <v-card-text>

                      {{ invoice.user.names }} <br>
                      <span v-if="invoice.user.address">
                        {{ invoice.user.address }}
                        <span v-if="invoice.user.addressLine2">{{ invoice.user.addressLine2 }}<br></span>
                        {{ invoice.user.city }} {{ invoice.user.state }}, {{ invoice.user.postalCode }} <br>
                      </span><br>

                      {{ invoice.user.email }}<br>
                      {{ invoice.user.phoneNumber }}
                    </v-card-text>

                  </v-list-item-content>
                </v-list-item>

              </v-card>
            </v-col>
            <v-col
                cols="12"
                lg="4"
                sm="12"
            >

              <v-card outlined>

                <v-form ref="invoiceStatusForm"
                        v-model="invoiceStatusValid"
                        lazy-validation
                >

                  <v-list-item two-line>
                    <v-list-item-content>

                      <v-container>
                        <v-row>
                          <v-col
                              sm="6"
                          >
                            Invoice #:
                          </v-col>
                          <v-col
                              sm="6"
                          >
                          {{ invoice.id }}
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                              sm="6"
                          >
                            Invoice Date:
                          </v-col>
                          <v-col
                              sm="6"
                          >
                            {{ invoice.invoiceDate | formatDate }}
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                              sm="6"
                          >
                            Due Date:
                          </v-col>
                          <v-col
                              sm="6"
                          >
                            {{ invoice.dueDate | formatDate }}
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                              sm="6"
                          >
                            Status:
                          </v-col>
                          <v-col
                              sm="6"
                          >
                            <v-select
                                v-model="computedStatus"
                                label="Invoice Status"
                                :items="invoiceStatusMap"
                                persistent-hint
                                single-line
                                :rules="rules.required"
                                hide-details="auto"
                                @change="validateInvoiceStatusForm"
                            ></v-select>
                          </v-col>
                        </v-row>


                      </v-container>

                    </v-list-item-content>
                  </v-list-item>

                </v-form>

              </v-card>

            </v-col>
          </v-row>


          <v-row>
            <v-col
                sm="12"
            >

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-subtitle-2 item-date">Date</th>
                    <th class="text-subtitle-2 item-description">Description</th>
                    <th class="text-subtitle-2 text-right item-price">Price</th>
                    <th class="text-subtitle-2 text-right item-qty">Qty</th>
                    <th class="text-subtitle-2 text-right item-total">Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="item in invoice.items"
                      :key="item.id"
                  >
                    <td>
                      {{ invoice.invoiceDate | formatDate }}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td class="text-right">
                      {{ item.price | formatCurrency }}
                    </td>
                    <td class="text-right">
                      {{ item.quantity }}
                    </td>
                    <td class="text-right">
                      {{ item.amount | formatCurrency }}
                    </td>
                  </tr>

                  <tr
                      v-for="item in invoice.discounts"
                      :key="item.id"
                  >
                    <td>
                      {{ invoice.invoiceDate | formatDate }}
                    </td>
                    <td>
                      Discount: {{ item.description }}
                    </td>
                    <td class="text-right">
                      -{{ item.value | formatCurrency }}
                    </td>
                    <td class="text-right">
                      1
                    </td>
                    <td class="text-right">
                      -{{ item.value | formatCurrency }}
                    </td>
                  </tr>


                  <tr
                      v-for="item in invoice.payments"
                      :key="item.id"
                  >
                    <td>
                      {{ item.date | formatDate }}
                    </td>
                    <td>
                      <router-link :to="{ name: 'add_edit_payment', params: {invoiceId: invoice.id, paymentId: item.id} }">
                        Payment method: {{ item.method }} ref #: {{ item.reference }}
                        <span v-if="item.status !== 1">({{ paymentStatusMap[item.status] }})</span>
                      </router-link>
                    </td>
                    <td class="text-right">
                      <span :class="{ 'text-decoration-line-through': item.status == 2 }">
                        -{{ item.amount | formatCurrency }}
                      </span>
                    </td>
                    <td class="text-right">
                      1
                    </td>
                    <td class="text-right">
                      <span v-if="item.status === 1">
                        -{{ item.amount | formatCurrency }}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="4" class="font-weight-bold">
                      Balance
                    </td>
                    <td class="text-right font-weight-bold">
                      {{ invoice.balance | formatCurrency }}
                    </td>
                  </tr>

                  </tbody>
                </template>
              </v-simple-table>


            </v-col>
          </v-row>

          <v-row>
            <v-col
              sm="2"
            >
              Memo:
            </v-col>
            <v-col
              sm="10"
              v-if="invoice.claim"
            >

              <strong>Claim by User:</strong> {{ invoice.claim.user.names }} <br>
              <strong>Dependent:</strong> {{ invoice.claim.dependent.names }} <br>
              <strong>Relationship:</strong> {{ invoice.claim.dependent.relationType.name }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
                sm="3"
                v-if="invoice.comments"
            >
              Notes:
            </v-col>
            <v-col
              sm="9"
              >
              {{ invoice.comments }}
            </v-col>
          </v-row>

          <v-row v-if="showMakePayment">
            <v-col>
              <router-link :to="{name: 'add_edit_payment', params: {invoiceId: invoice.id}}">
                <v-btn
                    color="primary"
                    type="submit"
                    id="submitPayment"
                >
                  Add Payment
                </v-btn>
              </router-link>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              Please note that payments will not be applied to the invoice until they are reviewed by an administrator.
            </v-col>
          </v-row>

          </v-card-text>

        </v-responsive>

      </base-material-card>


      <v-expansion-panels
          multiple
          light
          hover
          v-model="expandedPanels"
          v-if="invoiceId"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>Audit Trail</v-expansion-panel-header>

          <v-expansion-panel-content>
            <shared-audit-trail-view :item-id="invoiceId"></shared-audit-trail-view>
          </v-expansion-panel-content>

        </v-expansion-panel>
      </v-expansion-panels>


    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import constants from '@/utils/constants'
import validation from "@/utils/validation";
import SharedAuditTrailView from "../shared/AuditTrailView";

export default {
  name: 'CoreViewInvoiceView',

  components: {
    SharedAuditTrailView
  },

  created() {
    this.invoiceId = this.$route.params.invoiceId
    this.fetchInvoice()
  },
  computed: {
    computedStatus: {
      get() {
        return this.invoiceStatusMap[this.invoice.status]
      },
      set(value) {
        this.invoice.status = this.invoiceStatusMap.indexOf(value)
      }
    },
    showMakePayment() {
      if (this.invoice.status === constants.INVOICE_STATUS_PAID || this.invoice.balance === 0) {
        return false
      }

      for (const pmt of this.invoice.payments) {
        if (pmt.status === constants.PAYMENT_STATUS_ADMIN_REVIEW) {
          return false
        }
      }
      return true
    }
  },
  watch: {
    messages: function () {
      scroll(0, 0)
    },
    warnings: function () {
      scroll(0, 0)
    },
    errors: function () {
      scroll(0, 0)
    }
  },

  methods: {
    validateInvoiceStatusForm() {
      this.messages = []
      this.errors = []
      if(this.$refs.invoiceStatusForm.validate()) {
        this.setInvoiceStatus()
      }
    },
    fetchInvoice() {
      api
          .fetchInvoice(this.invoiceId)
          .then(response => {
            this.invoice = response.data
          })
          .catch(err => {
            this.errors.push(api.parseResponseError(err))
          })
    },
    setInvoiceStatus() {
      const payload = {
        status: this.invoice.status
      }
      api
          .setInvoiceStatus(this.invoice.id, payload)
          .then(response => {
            if (response.status === 200) {
              this.messages.push('Invoice status updated successfully')
              this.fetchInvoice()
            }
          })
          .catch(err => {
            this.errors.push(api.parseResponseError(err))
          })
    },
    createPayment() {
      const invoiceId = this.invoice.id
      api
          .createPayment(invoiceId, this.payment)
          .then(response => {
            if (response.status === 201) {
              this.$refs.paymentForm.reset()
              this.fetchInvoice()
              this.messages.push('Payment added successfully')
            }
          })
          .catch(err => {
            this.errors.push(api.parseResponseError(err))
          })
    }
  },

  data() {
    return {
      invoiceId: null,
      paymentValid: true,
      invoiceStatusValid: true,
      errors: [],
      messages: [],
      warnings: [],
      invoice: null,
      invoiceStatusMap: constants.getInvoiceStatusMap(),
      payment: {},
      paymentMethods: constants.getPaymentMethods(),
      paymentStatusMap: constants.getPaymentStatusMap(),
      expandedPanels: [0],
      rules: {
        required: [
          v => validation.isRequired(v) || 'required'
        ],
        currency: [
          v => validation.isCurrency(v) || 'must be in currency format'
        ],
      }
    }
  }
}
</script>



<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  .v-list-item
    .v-input
      padding-top: 0
      margin-top: 0

  th.item-date, th.item-price, th.item-total
    width: 10%
  th.item-qty
    width: 5%

</style>